/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AppWrapper from "../views/AppWrapper"
import Content, { Inner } from "../elements/Content"
import Button from "../elements/Button"
import Hero from "../views/Hero"
import { BigTitleLight, H2, P, PLight, Highlight } from "../elements/Text"

const SectionWrapper = styled(Inner)`
  ${tw`lg:flex lg:flex-wrap`}
`

const SectionContainer = styled.div`
  ${tw`w-full xl:w-1/2 mb-8 text-center bg-white px-4 xl:px-12 py-4 xl:pb-8`};
  ${props => (props.isHighlighted ? tw`bg-grey-lightest` : ``)}
`

const SectionMore = styled(P)`
  ${tw`text-grey-dark`};
`

const SectionTitle = styled(H2)`
  ${tw`uppercase text-pink`};
`

const CareersPage = () => (
  <Layout>
    <SEO title="Careers at Featurist" />
    <AppWrapper>
      <Hero isDark style={{ zIndex: 20 }}>
        <BigTitleLight>
          Carreers at
          <Highlight> Featurist</Highlight>
        </BigTitleLight>
        <PLight />
      </Hero>
      <Content>
        <SectionWrapper>
          <SectionContainer>
            <SectionTitle>Communication</SectionTitle>
            <P>
              We value high quality software, but we equally value communicating
              and solving problems as a team.
            </P>
            <SectionMore>
              We're flexible with where we work, and have many exciting projects
              in progress and coming up.
            </SectionMore>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Trust</SectionTitle>
            <P>
              Many of us have young families, flexibility and trust is critical
              to our team ethos.
            </P>
            <SectionMore>
              If you are not able to do a traditional commute to London and
              fixed hours we know that doesn't affect your ability to deliver
              great work.
            </SectionMore>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Learning</SectionTitle>
            <P>We value open source, easy to use, well tested code.</P>
            <SectionMore>
              We love to engage with the wider development community through
              conferences (attending and talking), and try to make and take the
              opportunities to continuously learn and improve our skills.
            </SectionMore>
          </SectionContainer>
          <SectionContainer isHighlighted>
            <SectionTitle>Open for approaches</SectionTitle>
            <P>
              We are not your traditional top-down organisation, all of us take
              responsibility, give respect and get a clear voice in Featurist.
            </P>
            <SectionMore>Think you'd enjoy working with us?</SectionMore>
            <Link to="/#contact">
              <Button>Get in touch &raquo;</Button>
            </Link>
          </SectionContainer>
        </SectionWrapper>
      </Content>
    </AppWrapper>
  </Layout>
)

export default CareersPage
