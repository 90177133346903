import styled from "styled-components"
import tw from "tailwind.macro"
// import { Link } from "gatsby";

const Button = styled.button`
  ${tw`bg-transparent hover:bg-pink border border-solid hover:border-transparent rounded cursor-pointer`};
  ${tw`text-base xl:text-lg font-semibold py-4 px-8`};
  ${tw`border-grey text-grey-darkest hover:text-white`}
`

const ButtonLight = styled(Button)`
  ${tw`border-white text-grey-lightest`}
`

export default Button
export { ButtonLight }
